<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";

  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    name:'notificationsList',
    page: {
      title: "Lista notifiche",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista notifiche",
       
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        responsesList: [
        ],
        nRead:0,
        nNotRead:0,
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        form:{
          start:moment().format('YYYY-MM-DD'),
          end:moment().format('YYYY-MM-DD'),
          status:'init',
          select:'',
        },
        n_results:0,
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        minDateEnd:moment().format('YYYY-MM-DD'),
        maxDateEnd:new Date().getFullYear().toString() + '-12-31',
        dateRange:{
            start_tmp: moment().unix(),
            start_str: moment().format('DD/MM/YYYY HH:MM'),
            end_str: moment().add(10,'days').format('DD/MM/YYYY HH:MM'),
            end_tmp:moment().add(10,'days').unix(),
        }
      };
    },
    components: {
      //lottie: Lottie,
  
     
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
      axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/my-dashboard')                  
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}notifications/per-page/by-user`, 
            { 
            params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
            }, 
            headers: { 'authorization':this.tk}
            }
        ).then(response=>{
            this.responsesList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            if (response.data.n_results > 0) {
                let nRead =  this.responsesList.filter(function(el){
                    return el.msgRead == true
                })
                let nNotRead = this.responsesList.filter(function(el){
                    return el.msgRead !== true
                })
                if (nRead) {
                    this.nRead = nRead.length
                }
                if (nNotRead) {
                    this.nNotRead = nNotRead.length
                }
            }
        })
      },
     
      readMSG(item,type) {
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}notifications/${item._id}`, {msgRead:true} ,{ headers: { 'authorization':this.tk} })
            .then(response=>{
                if (response.data) {
                    if (type=='tasks') {
                        this.$router.push('/tasks/detail/'+item.entityID)
                    }
                    if (type=='events') {
                        this.$router.push('/my-calendar')
                    }
                }
            })
      },
      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('exampleModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },

      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    }
    },
    mounted() {

    }
  };
</script>

<template>    
<!-- Modulo tabella elenco progetti/notifiche -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <template v-if="responsesList.length>0">
              <h3 class="card-title mb-0 flex-grow-1 primary">
                <b-alert show variant="secondary" >
                 Notifiche presenti: <strong>{{responsesList.length}} </strong>
                 
                 <i class="ri-mail-fill fs-16 pl-20 bold text-red">{{nNotRead}}</i> Non Lette
                 <i class="ri-mail-open-line fs-16 pl-20 bold text-green">{{nRead}}</i> Lette
                </b-alert>                          
              </h3>
              </template>
              <div class="flex-shrink-0">
               
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <template v-if="responsesList.length>0">
              <div class="table-card mt-50">
                  <div class="row thRow mt-50">
                        <div class="col-lg-2 col-md-12">
                          Tipo
                      </div>
                      <div class="col-lg-2 col-md-12">
                          Titolo
                      </div>
                      <div class="col-lg-2 col-md-12">
                        Messaggio
                      </div>
                      <div class="col-lg-2 col-md-12">
                          Stato
                      </div>
                      <div class="col-lg-2 col-md-12 text-center">
                          Visualizza
                      </div>
                  </div>
                <div class="row list tRow form-check-all d-flex" v-for="(item, index) of responsesList" :key="index">
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            <div class="avatar-xs me-3">
                                <template v-if="item.entity=='events'">
                                    <span class="avatar-title bg-soft-primary text-info rounded-circle fs-16" :title="item.message">
                                        <i class="bx bx-calendar"></i>
                                    </span>
                                </template>
                                <template v-if="item.entity=='tasks'">
                                    <span class="avatar-title bg-soft-info text-info rounded-circle fs-16" :title="item.message">
                                        <i class="mdi mdi-clipboard-edit-outline"></i>
                                    </span>
                                </template>
                                <template v-if="item.entity=='projects'" >
                                    <span class="avatar-title bg-soft-warning text-info rounded-circle fs-16" :title="item.message">
                                        <i class="mdi mdi-account-hard-hat "></i>
                                    </span>
                                </template>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            {{ item.title }}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                            <template v-if="item.content">
                                {{ item.content }}
                            </template>
                        </div>
                    </div>
                      <div class="col-lg-2 col-md-12">
                          <div class="list-inline hstack gap-2 mb-0">    
                            <template v-if="item.msgRead">
                                <template v-if="item.msgRead==false">
                                    Non letto
                                </template>
                                <template v-else>
                                    Letto
                                </template>
                            </template>
                            <template v-else>
                                Non letto
                            </template>
                          </div>
                      </div>
                      <div class="col-lg-2 col-md-12">
                          <ul class="list-inline text-center">
                              <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                                <template v-if="item.entity=='tasks'">
                                  <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="readMSG(item,'tasks')">
                                    <template v-if="item.msgRead">
                                        <template v-if="item.msgRead==false">
                                            <i class="ri-mail-fill fs-16"></i>
                                        </template>
                                        <template v-else>
                                            <i class="ri-mail-open-line fs-16"></i>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <i class="ri-mail-fill fs-16"></i>
                                    </template>
                                  </a>
                                </template>
                                <template v-if="item.entity=='events'">
                                  <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="readMSG(item,'events')">
                                    <a href="javascript:void(0)" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn" @click="readMSG(item,'tasks')">
                                    <template v-if="item.msgRead">
                                        <template v-if="item.msgRead==false">
                                            <i class="ri-mail-fill fs-16"></i>
                                        </template>
                                        <template v-else>
                                            <i class="ri-mail-open-line fs-16"></i>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <i class="ri-mail-fill fs-16"></i>
                                    </template>
                                  </a>

                                  </a>
                                </template>
                              </li>
                          </ul>
                      </div>
                </div>
                
              </div>
            </template>
            <template v-else> 
              <h3><strong>Nessuna notifica presente</strong></h3>
            </template>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

<!-- / Modulo tabella elenco progetti/notifiche -->      
</template>